@charset "UTF-8";
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes zoom-in-hover {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes sub_menu_fade_in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .5;
	}
}

@keyframes pop-up {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes go_title {
	0% {
		transform: scale(0.9);
		opacity: 0;
	}
	80% {
		transform: scale(1.1);
		opacity: 1;
	}
	90% {
		transform: scale(1.05);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes go_title2 {
	0% {
		transform: scale(0.5) translateY(80%);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0);
		opacity: 1;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.wrapper {
	width: auto;
	min-width: 1000px;
}

.root {
	min-width: 1000px;
	box-sizing: border-box;
	padding-top: 167px;
}

.pc_container,
.pc_tb_container,
.container {
	width: 1000px;
	min-width: 1000px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

.pc_container-relative,
.pc_tb_container-relative,
.container-relative {
	position: relative;
	top: 0;
	left: 0;
}

.pc_container-sm,
.pc_tb_container-sm,
.container-sm {
	width: 500px;
	min-width: 500px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

.pc_container-sm2,
.pc_tb_container-sm2,
.container-sm2 {
	width: 300px;
	min-width: 300px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
}

.pc_padding,
.padding {
	padding-left: 15px;
	padding-right: 15px;
}

.main_wrapper {
	display: flex;
	margin-bottom: 150px;
	padding-top: 30px;
}

.main_column {
	order: 2;
	width: 750px;
	box-sizing: border-box;
}

.sub_column {
	order: 1;
	width: 250px;
	padding-left: 10px;
	padding-right: 20px;
	box-sizing: border-box;
}

.main_content {
	font-size: 0.938rem;
	margin-bottom: 7em;
	padding-left: 15px;
	padding-right: 15px;
}

.scroll_to_top {
	right: 15px;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}

.block,
.pc_block {
	display: block;
}

.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
}

.root .tel_link,
.root .tel_link:hover, .root
.tel_link-no,
.root
.tel_link-no:hover {
	text-decoration: none;
	color: inherit;
}

.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.pc_column2 > *:nth-child(2n) {
	padding-bottom: 20px;
}

.pc_column2 > *:last-child {
	padding-bottom: 0;
}

.pc_column3 > *:nth-child(3n) {
	padding-bottom: 20px;
}

.pc_column3 > *:last-child {
	padding-bottom: 0;
}

.pc_column4 > *:nth-child(4n) {
	padding-bottom: 20px;
}

.pc_column4 > *:last-child {
	padding-bottom: 0;
}

.pc_column2::before, .pc_column2::after {
	content: '';
	display: table;
}

.pc_column2::after {
	clear: both;
}

.pc_column2 > * {
	width: 50%;
	float: left;
	box-sizing: border-box;
}

.pc_column2 > *:nth-child(2n + 1) {
	clear: left;
	padding-right: 15px;
}

.pc_column2 > *:nth-child(2n) {
	padding-left: 15px;
}

.pc_column3 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.pc_column3 > * {
	width: 220px;
	margin-right: 30px;
}

.pc_column3 > *:nth-child(3n) {
	margin-right: 0;
}

.pc_column4::before, .pc_column4::after {
	content: '';
	display: table;
}

.pc_column4::after {
	clear: both;
}

.pc_column4::before, .pc_column4::after {
	content: '';
	display: table;
}

.pc_column4::after {
	clear: both;
}

.pc_column4 > * {
	width: 25%;
	float: left;
	box-sizing: border-box;
}

.pc_column4 > *:nth-child(4n + 1) {
	clear: left;
	padding-right: 12px;
}

.pc_column4 > *:nth-child(4n + 2) {
	padding-left: 4px;
	padding-right: 8px;
}

.pc_column4 > *:nth-child(4n + 3) {
	padding-left: 8px;
	padding-right: 4px;
}

.pc_column4 > *:nth-child(4n) {
	padding-left: 12px;
}

.pc_column2_2 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.pc_column2_2 > * {
	width: 360px;
	margin-right: 10px;
}

.pc_column2_2 > *:nth-child(2n) {
	margin-right: 0;
}

.pc_column2_2 > *:nth-child(2n) {
	padding-bottom: 20px;
}

.pc_column2_2 > *:last-child {
	padding-bottom: 0;
}

.set::before, .set::after {
	content: '';
	display: table;
}

.set::after {
	clear: both;
}

.set .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 330px;
	margin-left: -300px;
}

.set .fix.left {
	width: 300px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 330px;
	margin-right: -300px;
}

.set .fix.right {
	width: 300px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set .fix > img:not(.cancel),
.set .resize > img:not(.cancel) {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
}

.set-2 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 220px;
	margin-left: -200px;
}

.set-2 .fix.left {
	width: 200px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-2 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 220px;
	margin-right: -200px;
}

.set-2 .fix.right {
	width: 200px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.set-3 .resize.right {
	width: 100%;
	box-sizing: border-box;
	float: right;
	padding-left: 260px;
	margin-left: -240px;
}

.set-3 .fix.left {
	width: 240px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: left;
}

.set-3 .resize.left {
	width: 100%;
	box-sizing: border-box;
	float: left;
	padding-right: 260px;
	margin-right: -240px;
}

.set-3 .fix.right {
	width: 240px;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: right;
}

.footer {
	position: relative;
	top: 0;
	left: 0;
}

.footer-scroll_to_top_stop .scroll_to_top {
	position: absolute;
	top: -100px;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
	height: 167px;
}

.header__1 {
	height: 86px;
	padding-top: 20px;
	box-sizing: border-box;
	background-color: #fff;
}

.header__1__inner {
	display: flex;
	justify-content: space-between;
}

.header__1__1 {
	width: 360px;
}

.header__1__2 {
	display: flex;
	justify-content: flex-end;
	width: 610px;
}

.header__2 {
	border-top: 1px solid #ffefd5;
	box-sizing: border-box;
	background-color: #ffefd5;
	height: 81px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.header__logo {
	width: 360px;
}

.header__tel {
	height: 46px;
	margin-right: 18px;
	min-width: 230px;
}

.header__contact {
	text-indent: -119988px;
	overflow: hidden;
	text-align: left;
	min-width: 220px;
	height: 46px;
	margin-right: 19px;
}

.header__contact a {
	background: url("../img/pc/header_contact_btn.png") 0 0/220px 46px no-repeat transparent;
	height: 46px;
	width: 220px;
}

.header__contact a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.header__contact a,
.header__contact img {
	display: block;
	max-width: 100%;
}

.header__fontsize {
	min-width: 66px;
	height: 46px;
	margin-right: 12px;
}

.header__fontsize .label {
	font-size: 12px;
	line-height: 1;
	margin-bottom: 3px;
}

.header__search {
	min-width: 46px;
}

.header__search a {
	width: 46px;
	height: 46px;
	background-color: #e1e1e1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header__search a:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.header__search img {
	display: block;
	width: 30px;
	height: 30px;
}

.header._show_sub_menu::after {
	content: '';
	width: 100vw;
	height: 100vh;
	background: #fff;
	opacity: .5;
	position: fixed;
	top: 0;
	left: 0;
	animation: sub_menu_fade_in 0.3s;
	z-index: -1;
}

.gnav__ul {
	display: flex;
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
}

.gnav__li {
	min-width: 143px;
}

.gnav__li:last-child {
	min-width: 142px;
}

.gnav__a {
	display: block;
	height: 80px;
	padding-top: 14px;
	box-sizing: border-box;
}

.gnav__a.active, .gnav__a._hover, .gnav__a:hover {
	background-color: #fff;
}

.gnav__a.active .gnav__text, .gnav__a._hover .gnav__text, .gnav__a:hover .gnav__text {
	color: #f18d00;
}

.gnav__a.active {
	border-bottom: 5px solid #f18d00;
}

.gnav__a.active._hover {
	border-bottom: 0 none;
}

.gnav__icon {
	display: block;
	width: 50px;
	height: 30px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 0;
}

.gnav__icon-1 {
	background: url("../img/pc/gnav1.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-2 {
	background: url("../img/pc/gnav2.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-3 {
	background: url("../img/pc/gnav3.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-4 {
	background: url("../img/pc/gnav4.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-5 {
	background: url("../img/pc/gnav5.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-6 {
	background: url("../img/pc/gnav6.png") 0 0/contain no-repeat transparent;
}

.gnav__icon-7 {
	background: url("../img/pc/gnav7.png") 0 0/contain no-repeat transparent;
}

.gnav__text {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: normal;
	text-align: center;
	font-size: 16px;
	line-height: 1;
	color: #1d110a;
	font-weight: 500;
}

.gnav__text .nakaguroten {
	letter-spacing: -.45ex;
	margin-left: -.3ex;
}

.gnav .sub_ul2 {
	display: none;
}

.gnav_sub_area {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #fff;
	width: 1000px;
	height: 0;
	overflow: hidden;
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}

.gnav_sub_area._show {
	height: auto;
	display: block;
	animation-name: fade-in;
	animation-duration: .3s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
}

.gnav_sub_area._leave {
	height: auto;
	display: block;
	animation-name: fade-out;
	animation-duration: .2s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
}

.gnav_sub {
	padding: 30px 26px 30px 48px;
	width: 926px;
	box-sizing: content-box;
}

.gnav_sub .btn {
	font-size: 15px;
	padding: .5em 25px .4em 20px;
	line-height: 1;
	min-height: 30px;
	border-radius: 15px;
	margin-bottom: 20px;
}

.gnav_sub .btn::before {
	content: '';
	display: inline-block;
	background: url("../img/icon/arrow/white.png") 0 center no-repeat transparent;
	background-size: 8px 10px;
	width: 8px;
	height: 10px;
	position: relative;
	top: 0;
	left: 0;
	margin-right: 5px;
}

.gnav_sub__thumb_list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 15px;
}

.gnav_sub__thumb_list > * {
	width: 300px;
	margin-right: 13px;
}

.gnav_sub__thumb_list > *:nth-child(3n) {
	margin-right: 0;
}

.gnav_sub__thumb_list .a1:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.gnav_sub__thumb_list .gnav__sub_image {
	border-radius: 5px;
	display: block;
	margin-bottom: 2px;
}

.gnav_sub__thumb_list .text {
	font-size: 16px;
	color: #1d110a;
}

.gnav_sub__thumb_list .text::before {
	content: '';
	display: inline-block;
	background: url("../img/icon/arrow/link_orange.png") 0 center no-repeat transparent;
	background-size: 10px 10px;
	width: 10px;
	height: 10px;
	margin-right: 5px;
}

.gnav_sub__box_list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.gnav_sub__box_list .li1 {
	box-sizing: border-box;
	margin-bottom: 15px;
	display: flex;
	width: 220px;
	margin-right: 15px;
}

.gnav_sub__box_list .li1-4 {
	margin-right: 0;
}

.gnav_sub__box_list .li1-comment {
	display: none;
}

.gnav_sub__box_list .li1-department .a1 {
	justify-content: space-between;
}

.gnav_sub__box_list .a1 {
	display: flex;
	align-items: center;
	width: 100%;
	border: 1px solid #b7b7b7;
	min-height: 62px;
	color: #333;
	font-size: 15px;
	padding: 1ex 15px;
	box-sizing: border-box;
	line-height: 1.2;
	background-color: #fff;
	transition: background-color .1s linear;
}

.gnav_sub__box_list .a1 .text {
	position: relative;
	top: 0;
	left: 0;
	padding-left: 14px;
}

.gnav_sub__box_list .a1 .text::before {
	content: '';
	display: inline-block;
	background: url("../img/icon/arrow/orange.png") 0 center no-repeat transparent;
	background-size: 8px 10px;
	width: 8px;
	height: 10px;
	min-width: 8px;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -5px;
}

.gnav_sub__box_list .a1 .department_icon {
	width: 40px;
	min-width: 40px;
}

.gnav_sub__box_list .a1 .department_icon img {
	max-width: 100%;
}

.gnav_sub__box_list .a1:hover {
	background-color: #fff1e6;
}

.slider-gnav {
	position: absolute;
	top: 0;
	left: 0;
}

.fontsize {
	width: 60px;
	height: 30px;
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;
}

.fontsize li,
.fontsize a {
	width: 30px;
	height: 30px;
}

.fontsize li {
	float: left;
}

.fontsize__a {
	text-indent: -119988px;
	overflow: hidden;
	text-align: left;
	display: block;
}

.fontsize__a-m {
	background: url("../img/pc/header_fontsize.png") 0 -30px / 60px 60px no-repeat transparent;
}

.fontsize-medium .fontsize__a-m, .fontsize__a-m:hover {
	background: url("../img/pc/header_fontsize.png") 0 0 / 60px 60px no-repeat transparent;
}

.fontsize__a-l {
	background: url("../img/pc/header_fontsize.png") -30px -30px / 60px 60px no-repeat transparent;
	transform: translateX(-1px);
}

.fontsize-large .fontsize__a-l, .fontsize__a-l:hover {
	background: url("../img/pc/header_fontsize.png") -30px 0 / 60px 60px no-repeat transparent;
}

.page_title_line {
	min-height: 100px;
}

.page_title_line h2 {
	font-size: 34px;
}

.main_content {
	padding-left: 15px;
	padding-right: 15px;
}

.main_content h3:not(.cancel),
.main_content .h3-1,
.main_content .box,
.main_content .pagelink_box,
.main_content .contact_box {
	margin-left: -15px;
	margin-right: -15px;
	line-height: 1.3;
}

.main_content .pc_wide {
	margin-left: -15px;
	margin-right: -15px;
}

.main_content .box._indent {
	margin-left: 0;
	margin-right: 0;
}

.main_content h3:not(.cancel),
.main_content .h3 {
	font-size: 1.25rem;
	padding: .3em 10px;
	min-height: 40px;
	margin-bottom: 30px;
}

.main_content .h3-1 {
	font-size: 1.25rem;
	min-height: 40px;
	padding-left: 24px;
}

.main_content .h3-1::before {
	width: 16px;
	height: 16px;
	border-radius: 8px;
	top: 15px;
}

.main_content h4:not(.cancel),
.main_content .h4 {
	font-size: 1.125rem;
	line-height: 1.375;
	margin-bottom: 28px;
	border-left: 5px solid #f18d00;
	padding-left: 10px;
}

.main_content h4:not(.cancel)::before,
.main_content .h4::before {
	content: '';
	display: block;
	background-color: #a9a6a1;
	height: 1px;
	width: calc(100% + 5px);
	position: absolute;
	bottom: -8px;
	left: -5px;
}

.main_content h5:not(.cancel),
.main_content .h5 {
	font-size: 1.125rem;
	padding-left: 26px;
}

.main_content h5:not(.cancel)::before,
.main_content .h5::before {
	content: '';
	display: block;
	background: url("../img/pc/h4.png") 0 0 no-repeat transparent;
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
	top: 3px;
}

.main_content h6:not(.cancel),
.main_content .h6 {
	font-size: 1rem;
	margin-bottom: 1em;
}

.footer__1 {
	padding-bottom: 8px;
	display: flex;
	width: 100%;
	align-items: flex-end;
}

.footer__1__1 {
	margin-right: 6px;
}

.footer__2::before, .footer__2::after {
	content: '';
	display: table;
}

.footer__2::after {
	clear: both;
}

.footer__2__1 {
	margin-right: 35px;
	float: left;
}

.footer__2__2 {
	float: left;
}

.footer__2__3 {
	float: right;
}

.footer__logo,
.footer__logo a,
.footer__logo img {
	width: 230px;
	height: 50px;
}

.footer__address {
	font-size: 0.938rem;
}

.footer__tel,
.footer__tel a {
	width: 200px;
	height: 42px;
}

.footer__btn_area li:not(:last-child) {
	margin-right: 10px;
}

.footer__btn_area a {
	height: 42px;
	border-radius: 21px;
	padding-top: .6em;
	padding-bottom: .5em;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 14px;
	letter-spacing: -.15ex;
}

.footer__btn_area a::before {
	content: '';
	display: inline-block;
	background: url("../img/icon/arrow/footer_btn.png") 0 center no-repeat transparent;
	background-size: 6px 10px;
	width: 6px;
	height: 10px;
	margin-right: 2px;
}

.footer__menu {
	padding-top: .35em;
	display: flex;
	float: right;
}

.footer__menu li:not(:first-child) {
	margin-left: 20px;
}

.footer__menu a {
	font-size: 14px;
}

.footer__foot {
	min-height: 30px;
	padding-top: 7px;
}
