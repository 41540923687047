@charset "utf-8";
@import 'import/var';
@import 'import/animation';

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.wrapper {
	width: auto;
	min-width: $pc_container_width;
}

.root {
	min-width: $pc_container_width;
	box-sizing: border-box;
	padding-top: $pc_header_height;
}

@mixin container($width: $pc_container_width) {
	width: $width;
	min-width: $width;
	box-sizing: border-box;
	// padding-left: $pc_padding;
	// padding-right: $pc_padding;
	margin-left: auto;
	margin-right: auto;
}

.pc_container,
.pc_tb_container,
.container {
	@include container;
	&-relative {
		position: relative;
		top: 0;
		left: 0;
	}
	&-sm {
		@include container(500px);
	}
	&-sm2 {
		@include container(300px);
	}
}
.pc_padding,
.padding {
	padding-left: $pc_padding;
	padding-right: $pc_padding;
}

.main_wrapper {
	display: flex;
	margin-bottom: 150px;
	padding-top: 30px;
}

.main_column {
	order: 2;
	width: 750px;
	box-sizing: border-box;
}

.sub_column {
	order: 1;
	width: 250px;
	padding-left: 10px;
	padding-right: 20px;
	box-sizing: border-box;
}

.main_content {
	font-size: $pc_default_font_size;
	margin-bottom: 7em;
	padding-left: $pc_padding;
	padding-right: $pc_padding;
}

.scroll_to_top {
	right: $pc_padding;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}
.block,
.pc_block {
	display: block;
}
.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
	.root &,
	.root &:hover {
		text-decoration: none;
		color: inherit;
	}
}
.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/


/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
$column: (2, 3, 4);

@each $num in $column {
	.pc_column#{$num} > *:nth-child(#{$num}n) {
		padding-bottom: 20px;
	}
	.pc_column#{$num} > *:last-child {
		padding-bottom: 0;
	}
}


.pc_column2 {
	@include columns(2, 30px);
}
.pc_column3 {
	@include flex_columns(3, 220px, 30px);
}
.pc_column4 {
	@include clearfix;
	@include columns(4, 16px);
}

.pc_column2_2 {
	@include flex_columns(2, 360px, 10px);
	> * {
		&:nth-child(2n) {
			padding-bottom: 20px;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}

.set {
	@include clearfix;
	.resize.right {
		@include columns_flex(300px, right, 30px);
	}
	.fix.left {
		@include columns_fix(300px, left);
	}

	.resize.left {
		@include columns_flex(300px, left, 30px);
	}
	.fix.right {
		@include columns_fix(300px, right);
	}
	.fix,
	.resize {
		> img:not(.cancel) {
			@include default_image;
		}
	}
	&-2 {
		.resize.right {
			@include columns_flex(200px, right, 20px);
		}
		.fix.left {
			@include columns_fix(200px, left);
		}

		.resize.left {
			@include columns_flex(200px, left, 20px);
		}
		.fix.right {
			@include columns_fix(200px, right);
		}

	}
	&-3 {
		.resize.right {
			@include columns_flex(240px, right, 20px);
		}
		.fix.left {
			@include columns_fix(240px, left);
		}

		.resize.left {
			@include columns_flex(240px, left, 20px);
		}
		.fix.right {
			@include columns_fix(240px, right);
		}

	}
}

.footer {
	position: relative;
	top: 0;
	left: 0;
	&-scroll_to_top_stop {
		.scroll_to_top {
			position: absolute;
			top: ($scroll_to_top_height + 20) * -1;
		}
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

.header {
	height: $pc_header_height;

	&__1 {
		height: 86px;
		padding-top: 20px;
		box-sizing: border-box;
		background-color: #fff;
		&__inner {
			display: flex;
			justify-content: space-between;
		}

		&__1 {
			width: 360px;
		}
		&__2 {
			display: flex;
			justify-content: flex-end;
			width: 610px;
		}
	}
	&__2 {
		border-top: 1px solid #ffefd5;
		box-sizing: border-box;
		background-color: #ffefd5;
		height: 81px;
		box-shadow: 0 2px 2px 0 rgba(#000, .1);
	}

	&__logo {
		width: 360px;
	}

	&__tel {
		height: 46px;
		margin-right: 18px;
		min-width: 230px;
	}
	&__contact {
		@include hide-text;
		min-width: 220px;
		height: 46px;
		margin-right: 19px;
		a {
			@include on_opacity;
			background: url('../img/pc/header_contact_btn.png') 0 0 / 220px 46px no-repeat transparent;
			height: 46px;
			width: 220px;
		}
		a,
		img {
			display: block;
			max-width: 100%;
		}
	}
	&__fontsize {
		min-width: 66px;
		height: 46px;
		margin-right: 12px;
		.label {
			font-size: 12px;
			line-height: 1;
			margin-bottom: 3px;
		}
	}
	&__search {
		min-width: 46px;

		a {
			@include on_opacity;
			width: 46px;
			height: 46px;
			background-color: #e1e1e1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		img {
			display: block;
			width: 30px;
			height: 30px;
		}
	}

	// グロナビ hover している時に
	&._show_sub_menu {
		&::after {
			content: '';
			width: 100vw;
			height: 100vh;
			// background-color: #fff;
			background: #fff;
			opacity: .5;
			position: fixed;
			top: 0;
			left: 0;
			animation: sub_menu_fade_in 0.3s;
			z-index: -1;
		}
	}
}

.gnav {
	&__ul {
		display: flex;
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
		z-index: 2;
	}
	&__li {
		// min-width: (100% / 7);
		min-width: 143px;
		&:last-child {
			min-width: 142px;
		}
	}
	&__a {
		display: block;
		height: 80px;
		padding-top: 14px;
		box-sizing: border-box;
		&.active,
		&._hover,
		&:hover {
			background-color: #fff;
			.gnav__text {
				color: #f18d00;
			}
		}
		&.active {
			border-bottom: 5px solid palette('orange', 'default');
			&._hover {
				border-bottom: 0 none;
			}
		}
	}
	&__icon {
		display: block;
		width: 50px;
		height: 30px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 0;

		@for $i from 1 through 7 {
			&-#{$i} {
				background: url('../img/pc/gnav#{$i}.png') 0 0 / contain no-repeat transparent;
			}
		}
	}
	&__text {
		@include notosans;
		text-align: center;
		font-size: 16px;
		line-height: 1;
		color: #1d110a;
		font-weight: 500;

		.nakaguroten {
			letter-spacing: -.45ex;
			margin-left: -.3ex;
		}
	}

	.sub_ul2 {
		display: none;
	}
}

.gnav_sub_area {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #fff;
	width: 1000px;
	height: 0;
	overflow: hidden;
	box-shadow: 0 5px 8px rgba(#000, .3);

	&._show {
		height: auto;
		display: block;
		animation-name: fade-in;
		animation-duration: .3s;
		animation-timing-function: $easeOutCubic;
		animation-fill-mode: forwards;

	}
	&._leave {
		height: auto;
		display: block;
		animation-name: fade-out;
		animation-duration: .2s;
		animation-timing-function: $easeOutCubic;
		animation-fill-mode: forwards;
	}
}

.gnav_sub {
	padding: 30px 26px 30px 48px;
	width: 926px;
	box-sizing: content-box;

	.btn {
		font-size: 15px;
		padding: .5em 25px .4em 20px;
		line-height: 1;
		min-height: 30px;
		border-radius: 15px;
		margin-bottom: 20px;

		&::before {
			@include bg-inline('../img/icon/arrow/white.png', 8px, 10px);
			position: relative;
			top: 0;
			left: 0;
			margin-right: 5px;
		}
	}

	&__thumb_list {
		@include flex_columns(3, 300px, 13px);
		margin-bottom: 15px;

		.a1 {
			@include on_opacity;
		}

		.gnav__sub_image {
			border-radius: 5px;
			display: block;
			margin-bottom: 2px;
		}
		.text {
			font-size: 16px;
			color: #1d110a;

			&::before {
				@include bg-inline('../img/icon/arrow/link_orange.png', 10px, 10px);
				margin-right: 5px;
			}
		}
	}

	&__box_list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.li1 {
			box-sizing: border-box;
			margin-bottom: 15px;
			display: flex;
			width: 220px;
			margin-right: 15px;

			&-4 {
				margin-right: 0;
			}
			&-comment {
				display: none;
			}

			&-department {
				.a1 {
					justify-content: space-between;
				}
				.text {
					
				}
			}
		}
		.a1 {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			width: 100%;

			border: 1px solid #b7b7b7;
			min-height: 62px;
			color: #333;
			font-size: 15px;
			padding: 1ex 15px;
			box-sizing: border-box;
			line-height: 1.2;
			background-color: #fff;
			transition: background-color .1s linear;


			.text {
				// display: flex;
				// justify-content: space-between;
				// align-items: center;
				// &::before {
				// 	@include bg-inline('../img/icon/arrow/orange.png', 8px, 10px);
				// 	margin-right: 5px;
				// 	min-width: 8px;
				// }
				position: relative;
				top: 0;
				left: 0;
				padding-left: 14px;
				&::before {
					@include bg-inline('../img/icon/arrow/orange.png', 8px, 10px);
					// margin-right: 5px;
					min-width: 8px;
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -5px;
				}
			}


			.department_icon {
				width: 40px;
				min-width: 40px;
				img {
					max-width: 100%;
				}
			}

			&:hover {
				background-color: palette('orange', 'hover');
			}
		}
	}
}

.slider-gnav {
	position: absolute;
	top: 0;
	left: 0;
}

.fontsize {
	$width: 60px;
	$height: 30px;
	width: $width;
	height: $height;
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;

	li,
	a {
		width: ($width / 2);
		height: $height;
	}
	li {
		float: left;
	}
	&__a {
		@include hide-text();
		display: block;
		&-m {
			background: url('../img/pc/header_fontsize.png') 0 #{$height * -1} / #{$width} #{$height * 2} no-repeat transparent;
			.fontsize-medium &,
			&:hover {
				background: url('../img/pc/header_fontsize.png') 0 0 / #{$width} #{$height * 2} no-repeat transparent;
			}
		}
		&-l {
			background: url('../img/pc/header_fontsize.png') #{-1 * $width / 2} #{$height * -1} / #{$width} #{$height * 2} no-repeat transparent;
			transform: translateX(-1px);
			.fontsize-large &,
			&:hover {
				background: url('../img/pc/header_fontsize.png') #{-1 * $width / 2} 0 / #{$width} #{$height * 2} no-repeat transparent;
			}
		}
	}
}

.page_title_line {
	min-height: 100px;

	h2 {
		font-size: 34px;
	}
}

.main_content {
	padding-left: $pc_padding;
	padding-right: $pc_padding;

	h3:not(.cancel),
	.h3-1,
	.box,
	.pagelink_box,
	.contact_box {
		@include pc_unindent;
		line-height: 1.3;
	}
	.pc_wide {
		@include pc_unindent;
	}

	.box._indent {
		margin-left: 0;
		margin-right: 0;
	}

	h3:not(.cancel),
	.h3 {
		font-size: $fontsize20;
		padding: .3em 10px;
		min-height: 40px;
		margin-bottom: 30px;
		// padding: .35em 10px .34em;
		// min-height: 40px;
	}

	.h3 {
		&-1 {
			font-size: $fontsize20;
			min-height: 40px;
			padding-left: 24px;
			&::before {
				width: 16px;
				height: 16px;
				border-radius: 8px;
				top: 15px;
			}
		}
	}

	h4:not(.cancel),
	.h4 {
		$border_width: 5px;
		$border_padding: 8px;

		font-size: $fontsize18;
		line-height: (22 / 16);
		margin-bottom: $border_padding + 20px;
		border-left: $border_width solid palette('orange', 'default');
		padding-left: 10px;

		&::before {
			content: '';
			display: block;
			background-color: #a9a6a1;
			height: 1px;
			width: calc(100% + #{$border_width});
			position: absolute;
			bottom: - $border_padding;
			left: - $border_width;
		}
	}


	h5:not(.cancel),
	.h5 {
		font-size: $fontsize18;
		padding-left: 26px;
		&::before {
			@include bg-block('../img/pc/h4.png', 20px, 20px);
			top: 3px;
		}
	}
	h6:not(.cancel),
	.h6 {
		font-size: $fontsize16;
		margin-bottom: 1em;
	}
}

.footer {
	&__1 {
		padding-bottom: 8px;
		display: flex;
		width: 100%;
		align-items: flex-end;
		&__1 {
			margin-right: 6px;
		}
	}
	&__2 {
		@include clearfix;
		&__1 {
			margin-right: 35px;
			float: left;
		}
		&__2 {
			float: left;
		}
		&__3 {
			float: right;
		}
	}
	&__logo {
		&,
		a,
		img {
			width: 230px;
			height: 50px;
		}
	}
	&__address {
		font-size: $fontsize15;
	}
	&__tel {
		&,
		a {
			width: 200px;
			height: 42px;
		}
	}
	&__btn_area {
		li {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		a {
			height: 42px;
			border-radius: 21px;
			padding-top: .6em;
			padding-bottom: .5em;
			padding-left: 15px;
			padding-right: 15px;
			font-size: 14px;
			letter-spacing: -.15ex;

			&::before {
				@include bg-inline('../img/icon/arrow/footer_btn.png', 6px, 10px);
				margin-right: 2px;
			}
		}
	}
	&__menu {
		padding-top: .35em;
		display: flex;
		float: right;

		li {
			&:not(:first-child) {
				margin-left: 20px;
			}
		}
		a {
			font-size: 14px;
		}
	}
	&__foot {
		min-height: 30px;
		padding-top: 7px;
	}
}


