@charset "utf-8";

@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}
@keyframes zoom-in {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes zoom-in-hover {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes sub_menu_fade_in {
	0% { opacity: 0; }
	100% { opacity: .5; }
}

@keyframes pop-up {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes go_title {
	0% {
		transform: scale(.9);
		opacity: 0;
	}
	80% {
		transform: scale(1.1);
		opacity: 1;
	}
	90% {
		transform: scale(1.05);
		opacity: 1;	
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes go_title2 {
	0% {
		transform: scale(.5) translateY(80%);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0);
		opacity: 1;
	}
}
